<template>
  <div class="container">
    <div class="row card-header col-sm-pull-3" style="position: sticky; z-index: 1030; top: 0;">
      <h5>Application Details ({{primaryBorrower.firstName}} {{primaryBorrower.lastName}} - Application #{{application.id}})</h5>
      <div class="mx-auto float:right">
        <p>
          [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
          [ <router-link :to="{name: 'Quotes', params: {applicationId: passedApplicationId}}" class="custom-fg-dark">Quote</router-link> ]
        </p>
      </div>

    </div>

    <Notes :applicationId="passedApplicationId" :key="notesKey"/>

    <!-- when loan assignee is updated, Details component is reloaded -->
    <Details :applicationId="passedApplicationId" @loan-officer="section2Key++" />
    <Section2 :applicationId="passedApplicationId"
              :key="section2Key"
              :primaryBorrower="primaryBorrower"
              @refresh-notes="notesKey++"/>
    <Section3 :applicationId="passedApplicationId" />
    <Section4 :applicationId="passedApplicationId" @changePrimaryBorrower="changePrimaryBorrower"/>
  </div>
</template>

<script>
import Application from '@/services/ApplicationService'
import Borrower from '@/services/BorrowerService'

import Details from '@/components/applications/Details'
import Notes from '@/components/notes/Notes'
import Section2 from '@/components/applications/Section2'
import Section3 from '@/components/applications/Section3'
import Section4 from '@/components/applications/Section4'

export default {
  name: "ApplicationEdit",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  data: function () {
    return {
      application: {},
      passedApplicationId: 0,
      primaryBorrower: {},
      notesKey: 0,
      section2Key: 0
    }
  },
  components: {
    Details,
    Section2,
    Section3,
    Section4,
    Notes
  },
  created: async function () {
    this.passedApplicationId = this.$route.params.applicationId;

    this.application = await Application.getApplication(this.passedApplicationId);
    this.primaryBorrower = await Borrower.getPrimaryBorrower(this.passedApplicationId);
  },
  methods: {
    changePrimaryBorrower: function(borrower) {
      this.primaryBorrower = borrower;
    }
  },
}
</script>

