<template>
<div class="application-card">
  <div>
      <span class="badge badge-pill"><span>2</span></span><h4 class="application-card-header">Loan Information</h4>
  </div>

  <div id="EmailRateQuoteModal">
    <!-- Enabled by Email Rate Quote button, parameters passed from data -->
    <EmailRateQuoteModal
        v-if="showEmailRateQuote"
        :applicationId="applicationId"
        :loan="loan"
        :property="formData.property"
        :borrower="primaryBorrower"
        :loanOfficer="loanOfficer"
        :newFee="newFee"
        :promotionCode="this.promotionCode"
        :refinance-loan="this.refinanceLoan"
        :key="componentKey"
        @keydown.esc="cancelEmailRateQuote"
        @email-rate-quote="sendEmailRateQuote($event)" @cancel="cancelEmailRateQuote" />
  </div>

  <div id="ApplicationHistoryModal">
    <ApplicationHistoryModal
        v-if="showApplicationHistories"
        :application="application"
        :histories="sortedHistories"
        :refinance-loan="this.refinanceLoan"
        :borrower="primaryBorrower"
        @keydown.esc="closeApplicationHistoryModal"
        @close="closeApplicationHistoryModal"/>
  </div>

  <div class="card mt-2" id="section2">
    <div class="d-flex">
      <div class="d-inline-block small-section">
        <form class="ml-2 mr-2">
          <div class="form-row ml-2 mr-2">
            <div>
              <router-link :to="{name: 'Quotes', params: {applicationId: this.applicationId}}" class="btn btn-primary mt-2 ml-1">New Quote</router-link>
            </div>
            <div class="ml-auto">
              <button class="btn btn-primary mt-2" @click.prevent="showEmailRateQuoteModal()">Email Rate Quote</button>
              <small id="helpLoanOfficerBlock" class="ml-2 form-text text-danger" v-if="helpLoanOfficer">Consultant required</small>
            </div>
          </div>
          <div class="form-row ml-2 mr-2 mt-4">
            <div class="col-md-2">
              <label for="mortgageType" class="form-control-sm" style="margin-bottom: 0;">Mortgage Applied For</label>
            </div>
            <div class="col-md-2">
              <label for="amortizationType" class="form-control-sm" style="margin-bottom: 0;">Amortization Type</label>
            </div>
            <div class="col-md-2">
              <label for="loanPurpose" class="form-control-sm" style="margin-bottom: 0;">Lock Period</label>
            </div>
            <div class="col-md-2">
              <label for="loanPurpose" class="form-control-sm" style="margin-bottom: 0;">Loan Purpose</label>
            </div>
            <div class="col-md-2">
              <label for="propertyUse" class="form-control-sm" style="margin-bottom: 0;">Property Use</label>
            </div>
            <div class="col-md-2">
              <label for="propertyType" class="form-control-sm" style="margin-bottom: 0;">Property Type</label>
            </div>
          </div>
          <div class="form-row ml-2 mr-2">
            <div class="form-group col-md-2">
              <div
                  v-for="(mortgageType) in mortgageTypes"
                  :key="mortgageType.id"
                  class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.loan.loanType"
                    name="mortgageType"
                    :value="mortgageType.value"
                    :disabled="true"/>
                <label
                    class="form-check-label form-control-sm"
                    for="mortgageType.value"
                >{{mortgageType.value}}</label>
              </div>
            </div>
            <div class="form-group col-md-2">
              <div
                  v-for="(amortizationType) in amortizationTypes"
                  :key="amortizationType.id"
                  class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.loan.amortizationType"
                    name="amortizationType"
                    :value="amortizationType.value"
                    :disabled="true"/>
                <label
                    class="form-check-label form-control-sm"
                    for="amortizationType.value"
                >{{amortizationType.value}}</label>
              </div>
              <small id="helpAmortizationTypeBlock" class="ml-2 form-text text-danger" v-if="helpAmortizationType">Amortization Type required</small>
            </div>
            <div class="form-group col-md-2">
              <div
                  v-for="(lockPeriod) in lockPeriods"
                  :key="lockPeriod.id"
                  class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.loan.lockPeriod"
                    name="lockPeriod"
                    :value="lockPeriod.name"
                    :disabled="true"/>
                <label
                    class="form-check-label form-control-sm"
                    for="lockPeriod.name">{{lockPeriod.name}}</label>
              </div>
              <small id="helpLockPeriodBlock" class="ml-2 form-text text-danger" v-if="helpLockPeriod">Lock Period required</small>
            </div>
            <div class="form-group col-md-2">
              <div
                  v-for="(loanPurpose) in loanPurposes"
                  :key="loanPurpose.id"
                  class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.loan.loanPurpose"
                    name="loanPurpose"
                    :value="loanPurpose.name"
                    :disabled="true"/>
                <label
                    class="form-check-label form-control-sm"
                    for="loanPurpose.name">{{loanPurpose.name}}</label>
              </div>
              <small id="helpLoanPurposeBlock" class="ml-2 form-text text-danger" v-if="helpLoanPurpose">Loan Purpose required</small>
            </div>
            <div class="form-group col-md-2">
              <div v-for="(propertyUse) in propertyUses" :key="propertyUse.id" class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.property.propertyUse"
                    name="propertyUse"
                    :value="propertyUse.name"
                    :disabled="true"
                />
                <label
                    class="form-check-label form-control-sm"
                    for="propertyUse.name"
                >{{propertyUse.name}}</label>
              </div>
            </div>
            <div class="form-group col-md-2">
              <div v-for="(propertyType) in propertyTypes" :key="propertyType.id" class="form-check form-check-inline">
                <input
                    type="radio"
                    v-model="formData.property.propertyType"
                    name="propertyType"
                    :value="propertyType.name"
                    :disabled="true"
                />
                <label
                    class="form-check-label form-control-sm"
                    for="propertyType.name"
                >{{propertyType.name}}</label>
              </div>
            </div>
          </div>

          <div class="form-row ml-2 mr-2 mt-2">
            <div class="form-group col-md">
              <label for="amount" class="form-control-sm">Loan Amount</label>
              <span class="input-help">
            <input
                type="text"
                class="form-control form-control-sm"
                id="amount"
                placeholder="Loan Amount"
                v-model="formData.loan.amount"
                :readonly="true"/>
            <small id="ltvHelpBlock" class="ml-2 form-text text-info">{{calculateLTV()}}</small>
          </span>
            </div>
            <div class="form-group col-md" v-if="showCashOutAmount()">
              <label for="cashOutAmount" class="form-control-sm">Cash Out Amount</label>
              <span class="input-help">
            <input
                type="text"
                class="form-control form-control-sm"
                id="cashOutAmount"
                placeholder="$0"
                v-model="formData.refinanceLoan.cashOutAmount"
                :readonly="true"/>
          </span>
            </div>
            <div class="form-group col-md" v-if="showPropertyValue()">
              <label for="propertyValue" class="form-control-sm">Property Value</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="propertyValue"
                  placeholder="$"
                  v-model="formData.property.value"
                  :readonly="true"/>
            </div>
            <div class="form-group col-md" v-if="showPurchasePrice()">
              <label for="propertyValue" class="form-control-sm">Purchase Price</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="purchasePrice"
                  placeholder="$"
                  v-model="formData.property.purchasePrice"
                  :readonly="true"/>
              <small id="helpPurchasePriceBlock" class="ml-2 form-text text-danger" v-if="helpPurchasePrice">Purchase Price required</small>
            </div>
            <div class="form-group col-md">
              <label for="interestRate" class="form-control-sm">Interest Rate</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="interestRate"
                  placeholder="Interest Rate"
                  v-model="formData.loan.interestRate"
                  :readonly="true"/>
              <small id="helpInterestRateBlock" class="ml-2 form-text text-danger" v-if="helpInterestRate">Interest Rate required</small>
            </div>
            <div class="form-group col-md">
              <label for="apr" class="form-control-sm">APR</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="apr"
                  placeholder="APR"
                  v-model="formData.loan.apr"
                  :readonly="true"/>
              <small id="helpAprBlock" class="ml-2 form-text text-danger" v-if="helpApr">APR required</small>
            </div>
            <div class="form-group col-md">
              <label for="fee" class="form-control-sm">Fee</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="fee"
                  placeholder="$"
                  v-model="formData.loan.fee"
                  v-bind:style="{ 'text-decoration': this.feeStyle }"
                  :readonly="true"/>
              <small id="promoCodeBlock" v-if="this.application.promotionCode !== undefined" class="ml-2 form-text text-info">{{ this.promotionCode.promotionCode + ': $' + (this.promotionCode.discountAmount) }}</small>
              <small id="feeAfterPromo" v-if="this.application.promotionCode !== undefined" class="ml-2 form-text text-info">After Promo: {{ this.loan.fee - this.promotionCode.discountAmount | currency }}</small>
              <small id="helpFeeBlock" class="ml-2 form-text text-danger" v-if="helpFee">Fee required</small>
            </div>
          </div>
          <div class="form-row ml-2 mr-2">
            <div class="form-group col-md-2">
              <label for="credit" class="form-control-sm">Credit</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="credit"
                  placeholder="$"
                  v-model="formData.loan.credit"
                  :readonly="true"/>
              <small id="feeAfterCredit" v-if="this.loan.credit" class="ml-2 form-text text-info">After Credit: {{ this.newFee | currency }}</small>
            </div>
            <div class="form-group col-md-2">
              <label for="noOfMonths" class="form-control-sm">No. of Months</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="noOfMonths"
                  placeholder="Number of Months"
                  v-model="formData.loan.numberOfMonths"
                  :readonly="true"/>
            </div>
            <div class="form-group col-md-3">
              <div class="form-group">
                <label for="creditScore" class="form-control-sm">Credit Score</label>
                <select
                    class="custom-select custom-select-sm mb-2"
                    id="creditScore"
                    v-model="primaryBorrower.creditRating"
                    :disabled=true>
                  <option
                      v-for="(creditRating) in creditRatingList"
                      :key="creditRating"
                      :value="creditRating">{{creditRating}}</option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-2">
              <label for="apr" class="form-control-sm">Monthly Payment</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="monthlyPayment"
                  placeholder="Monthly Payment"
                  v-model="formData.loan.monthlyPayment"
                  :readonly="true"/>
            </div>
            <div class="form-group col-md">
              <label for="fee" class="form-control-sm">Total 3d Party Fee</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="totalThirdPartyFee"
                  placeholder="$"
                  v-model="formData.loan.totalThirdPartyFee"
                  v-bind:style="{ 'text-decoration': this.feeStyle }"
                  :readonly="true"/>
            </div>
          </div>

        </form>
      </div>

      <div class="d-inline-block mt-2 history-small-table-div">
        <table class="history-small-table table table-sm table-hover mb-0">
          <thead class="custom-bg-med">
          <tr>
            <th class="th-lg" scope="col" v-on:click="showApplicationHistoriesModal"> &#128336; Quote History </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(history) in sortedHistories" :key="history.id">
              <td v-on:click="showApplicationHistoriesModal">{{formatDate(history.created)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import lodash from 'lodash'
import numeral from 'numeral'

import '@/filters/TextFilters'

import Application from '@/services/ApplicationService'
import Loan from '@/services/LoanService'
import PickDetail from '@/services/PickDetailService'
import Property from '@/services/PropertyService'
import RefinanceLoan from '@/services/RefinanceLoanService'

import EmailRateQuoteModal from '@/components/modals/EmailRateQuoteModal'
import PromotionCode from "@/services/PromotionCodeService";
import ApplicationHistory from "@/services/ApplicationHistoryService";
import moment from "moment";
import ApplicationHistoryModal from "@/components/modals/ApplicationHistoryModal";

export default {
  name: 'section2',
  components: {
    ApplicationHistoryModal,
    EmailRateQuoteModal,
  },
  computed: {
    sortedHistories: function () {
      return this.applicationHistories.slice().sort(function (a, b) {
        return moment(b.created) - moment(a.created);
      })
    }
  },
  created: async function () {
    // Application
    this.application = await Application.getApplication(this.applicationId);
    this.applicationHistories = await ApplicationHistory.getApplicationHistories(this.applicationId);

    // Loan
    if (this.application !== undefined && this.application.loan !== undefined) {

      // populate loan officer details with Rate Rabbit defaults when missing
      this.loanOfficer = this.application.user;
      if (this.loanOfficer.nmlsNumber === undefined) {
        this.loanOfficer.nmlsNumber = "5250";
      }
      if (this.loanOfficer.corporateNmlsNumber === undefined) {
        this.loanOfficer.corporateNmlsNumber = "664689";
      }
      if (this.loanOfficer.phoneNumber === undefined) {
        this.loanOfficer.phoneNumber = "(888)395-0395"
      }
      if (this.loanOfficer.email === undefined) {
        this.loanOfficer.email = "bill@raterabbit.com"
      }

      this.loan = this.application.loan;
      this.formData.loan = lodash.cloneDeep(this.loan);

      this.formData.loan.amount = this.formatCurrency(this.loan.amount);
      this.formData.loan.fee = this.formatCurrency(this.loan.fee);
      this.formData.loan.totalThirdPartyFee = this.formatCurrency(this.loan.totalThirdPartyFee);
      this.formData.loan.credit = this.formatCurrency(this.loan.credit);
      this.formData.loan.apr = this.formatInterestRate(this.loan.apr);
      this.formData.loan.interestRate = this.$options.filters.percentage(this.loan.interestRate);
      this.formData.loan.monthlyPayment = this.formatCurrency(this.loan.mortgagePayment);
      this.formData.loan.purpose = this.loan.loanPurpose;

      // find application promotion code object if any
      if (this.application.promotionCode) {
        let promotionCodes = await PromotionCode.getPromotionCodes();

        promotionCodes.forEach((promoCode) => {
          if (promoCode && promoCode.promotionCode === this.application.promotionCode) {
            this.promotionCode = promoCode;
          }
        })
      }

      // promo code, credit calculations
      this.calculateFee();

      // Refinance Loan
      if (this.loan.refinanceLoanId > 0) {
        this.refinanceLoan = await RefinanceLoan.getRefinanceLoan(this.loan.refinanceLoanId);
        this.formData.refinanceLoan = lodash.cloneDeep(this.refinanceLoan);
        this.formData.refinanceLoan.cashOutAmount = this.formatCurrency(this.refinanceLoan.cashOutAmount);
      }

      // Property
      if (this.application.property !== undefined) {
        this.property = this.application.property;
        this.formData.property = lodash.cloneDeep(this.property);

        this.formData.property.value = this.formatCurrency(this.property.value);
        this.formData.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);
        if (this.formData.property.purchasePrice === undefined) {
          this.formData.property.purchasePrice = this.formatCurrency(this.property.propertyValue);
        }
      } else {
        this.property = {
          purchasePrice: "",
        }
      }
    }

    // Pick lists
    this.amortizationTypes = await PickDetail.getPickListDetail('amortizationtype');
    this.lockPeriods = await PickDetail.getPickListDetail('lockperiod');
    this.mortgageTypes = await PickDetail.getPickListDetail('mortgagetype');
    this.loanPurposes = await PickDetail.getPickListDetail('loanpurpose');
    this.propertyUses = await PickDetail.getPickListDetail('propertyuse');
    this.propertyTypes = await PickDetail.getPickListDetail('propertytype')
  },
  data: function () {
    return {
      amortizationTypes: [],
      lockPeriods: [],
      application: {},
      formData: {
        loan: {},
        property: {},
        refinanceLoan: {},
      },
      applicationHistories: [],
      showApplicationHistories: false,
      loan: {},
      loanPurposes: [],
      propertyUses: [],
      propertyTypes: [],
      mortgageTypes: [],
      promotionCode: {},
      property: {},
      refinanceLoan: {},
      showEmailRateQuote: false,
      emailRateQuoteError: {
        status: undefined,
        subject: undefined,
        description: undefined
      },
      loanOfficer: {},
      feeStyle: '',
      newFee: undefined,
      componentKey: 0,
      helpApr: false,
      helpInterestRate: false,
      helpPurchasePrice: false,
      helpAmortizationType: false,
      helpLoanPurpose: false,
      helpLockPeriod: false,
      helpFee: false,
      helpLoanOfficer: false,
      creditRatingList: [
        "",
        "740+ (Excellent)",
        "720 - 739",
        "700 - 719",
        "680 - 699",
        "660 - 679 (Fair)",
        "640 - 659",
        "620 - 639",
        "600 - 619",
        "580 - 599 (Poor)"
      ],
    }
  },
  methods: {
    updateQuote() {
      this.$router.push({name: 'Quotes', params: {applicationId: this.applicationId}})
    },
    formatDate: function (date) {
      return moment(date).format('MM/DD/YY hh:mm a');
    },
    showApplicationHistoriesModal: function () {
      if (this.applicationHistories.length > 0) {
        this.showApplicationHistories = true;
      }
    },
    calculateLTV: function() {
      let loanAmount = this.convertStringToNumber(this.loan.amount);
      let value = 0.0;

      if (this.showPurchasePrice()) {
        value = this.convertStringToNumber(this.property.purchasePrice);
      } else {
        value = this.convertStringToNumber(this.property.value);
      }

      this.loan.ltvPercent = (loanAmount / value) * 100;

      if (value > 0) {
        return "LTV: " + this.formatPercentage(this.loan.ltvPercent);
      } else {
        return "LTV: Undefined";
      }
    },
    calculateFee() {
      this.newFee = this.loan.fee;

      // if there is a promotional code applied, update the fee and set fee input field text style to strikethrough
      if (this.promotionCode && Object.keys(this.promotionCode).length > 0) {
        this.feeStyle = 'line-through';
        this.newFee = this.loan.fee - this.promotionCode.discountAmount;
      }

      if (this.loan.credit) {
        this.newFee = (this.newFee - this.loan.credit);
        if (this.newFee < 0) {
          this.newFee = 0;
        }
      }
    },
    changeLoan(fieldName) {
      if (this.formData.loan[fieldName] !== this.loan[fieldName]) {
        this.loan[fieldName] = this.formData.loan[fieldName];
        this.saveLoan();
      }
    },
    changeProperty: function (fieldName) {
      if (this.formData.property[fieldName] !== this.property[fieldName]) {
        this.property[fieldName] = this.formData.property[fieldName];
        this.saveProperty();
      }
    },
    changeRefinanceLoan: function(fieldName) {
      if (this.formData.refinanceLoan[fieldName] !== this.refinanceLoan[fieldName]) {
        this.refinanceLoan[fieldName] = this.formData.refinanceLoan[fieldName];
        this.saveRefinanceLoan();
      }
    },
    convertStringToNumber: function(val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val != "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    cancelEmailRateQuote: function() {
      this.showEmailRateQuote = false;
    },
    closeApplicationHistoryModal: function() {
      this.showApplicationHistories = false;
    },
    showEmailRateQuoteModal: function() {
      // validate fields
      let validated = true;

      if (this.loanOfficer.firstName === 'Unknown') {
        this.helpLoanOfficer = true;
        validated = false;
      }

      if (this.formData.loan.apr === undefined || this.formData.loan.apr === 0) {
        this.helpApr = true;
        validated = false;
      }

      if (this.formData.loan.interestRate === undefined || this.formData.loan.interestRate === 0) {
        this.helpInterestRate = true;
        validated = false;
      }

      if (this.formData.loan.purpose === undefined || this.formData.loan.purpose === '') {
        this.helpLoanPurpose = true;
        validated = false;
      }

      if (this.formData.loan.amortizationType === undefined || this.formData.loan.amortizationType === '') {
        this.helpAmortizationType = true;
        validated = false;
      }

      if (validated) {
        this.showEmailRateQuote = true;
      }
    },
    sendEmailRateQuote: async function () {
      this.showEmailRateQuote = false;

      this.$emit('refresh-notes');
    },
    formatCurrency: function (amount) {
      if (!amount) {
        return 'N/A';
      }

      return numeral(amount).format('$0,0');
    },
    formatInterestRate: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.000%');
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.00%');
    },
    saveLoan: async function () {
      delete(this.loan.created);  // Parsing dates was causing an exception on the server
      delete(this.loan.modified); // so kill these as they're set by the server anyway

      this.loan.amount = this.convertStringToNumber(this.loan.amount);
      this.loan.fee = this.convertStringToNumber(this.loan.fee);
      this.loan.credit = this.convertStringToNumber(this.loan.credit);
      this.loan.apr = this.convertStringToNumber(this.loan.apr);
      this.loan.interestRate = this.convertStringToNumber(this.loan.interestRate);
      this.loan.numberOfMonths = this.convertStringToNumber(this.loan.numberOfMonths);

      this.loan = await Loan.updateLoan(this.loan);

      this.formData.loan.amount = this.formatCurrency(this.loan.amount);
      this.formData.loan.fee = this.formatCurrency(this.loan.fee);
      this.formData.loan.credit = this.formatCurrency(this.loan.credit);
      this.formData.loan.apr = this.formatInterestRate(this.loan.apr);
      this.formData.loan.interestRate = this.formatInterestRate(this.loan.interestRate);

      this.calculateFee();
      // force refresh of email modal by updating a key value
      this.componentKey += 1;
    },
    saveProperty: async function () {
      delete(this.property.created);  // Parsing dates was causing an exception on the server
      delete(this.property.modified); // so kill these as they're set by the server anyway

      this.property.value = this.convertStringToNumber(this.property.value);
      this.property.purchasePrice = this.convertStringToNumber(this.property.purchasePrice);

      this.property = await Property.updateProperty(this.property);

      this.formData.property.value = this.formatCurrency(this.property.value);
      this.formData.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);
    },
    saveRefinanceLoan: async function () {
      if (this.refinanceLoan.id == null) {
        // We may not have a refinance loan record, so create one if needed
        let refinanceLoan = {
          improvementsMade: false,
          cashOutAmount: this.convertStringToNumber(this.refinanceLoan.cashOutAmount),
          loc: false,
          locAfterFirst: false,
          keepingLoc: false
        };

        this.refinanceLoan = await RefinanceLoan.createRefinanceLoan(refinanceLoan);

        this.loan.refinanceLoanId = this.refinanceLoan.id;
        await this.saveLoan();
      } else {
        // We had a refinance loan record, so update
        delete(this.refinanceLoan.created);  // Parsing dates was causing an exception on the server
        delete(this.refinanceLoan.modified); // so kill these as they're set by the server anyway

        this.refinanceLoan.cashOutAmount = this.convertStringToNumber(this.refinanceLoan.cashOutAmount);

        this.refinanceLoan = await RefinanceLoan.updateRefinanceLoan(this.refinanceLoan);
      }

      this.formData.refinanceLoan.cashOutAmount = this.formatCurrency(this.refinanceLoan.cashOutAmount);
    },
    showCashOutAmount: function() {
      return (this.loan.loanPurpose != null && this.loan.loanPurpose.toLowerCase().includes("cash out"));
    },
    showPropertyValue: function() {
      return (this.loan.loanPurpose != null && this.loan.loanPurpose !== 'Purchase');
    },
    showPurchasePrice: function () {
      return (this.loan.loanPurpose != null && this.loan.loanPurpose === 'Purchase');
    }
  },
  props: ['applicationId', 'primaryBorrower'],
}
</script>
