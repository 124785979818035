<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header font-weight-bold">
            <slot name="header">Borrower Detail for {{formData.borrower.firstName}} {{formData.borrower.lastName}}</slot>
          </div>
          <div class="modal-body">

            <form class="ml-2 mr-2">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-11">
                      <label for="borrowerTypeRadio" class="form-control-sm text-dark">Borrower Type</label>
                      <div
                        v-for="(borrowerType) in borrowerTypes"
                        :key="borrowerType.id"
                        class="form-check form-check-inline"
                      >
                        <input
                          type="radio"
                          name="borrowerTypeRadio"
                          :value="borrowerType.value"
                          v-model="formData.borrower.borrowerType"
                          @change="changeBorrower('borrowerType')"
                        />
                        <label
                          class="form-check-label form-control-sm mt-1"
                          for="borrowerTypeRadio"
                        >{{borrowerType.value}}</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-4">
                      <label for="firstName" class="form-control-sm text-dark">First Name</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="firstName"
                        placeholder="First Name"
                        v-model="formData.borrower.firstName"
                        @change="changeBorrower('firstName')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="lastName" class="form-control-sm text-dark">Last Name</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="lastName"
                        placeholder="Last Name"
                        v-model="formData.borrower.lastName"
                        @change="changeBorrower('lastName')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="ssn" class="form-control-sm text-dark">Social Security Number</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="ssn"
                        placeholder="SSN"
                        v-model="formData.borrower.ssn"
                        @change="changeBorrower('ssn')"
                        @keyup="formatSSN('ssn')"
                      />
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-4">
                      <label for="dob" class="form-control-sm text-dark">Date of Birth</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        id="dob"
                        v-model="formData.borrower.dob"
                        @change="changeBorrower('dob')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="martialStatusRadio" class="form-control-sm text-dark">Marital Status:</label>
                      <br />
                      <div
                        v-for="(maritalStatus) in maritalStatuses"
                        :key="maritalStatus.id"
                        class="form-check form-check-inline"
                      >
                        <input
                          type="radio"
                          name="martialStatusRadio"
                          :value="maritalStatus.value"
                          v-model="formData.borrower.maritalStatus"
                          @change="changeBorrower('maritalStatus')"
                        />
                        <label
                          class="form-check-label form-control-sm"
                          for="maritalStatus"
                        >{{maritalStatus.value}}</label>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="creditRating" class="form-control-sm text-dark">Credit Rating</label>
                      <select class="custom-select custom-select-sm" id="creditRating" v-model="formData.borrower.creditRating" @change="changeBorrower('creditRating')">
                        <option v-for="(creditRating) in creditRatings" :key="creditRating.id" :value="creditRating.name">
                          {{creditRating.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-12">
                      <label for="email" class="form-control-sm text-dark">Email</label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="email"
                        placeholder="Email Address"
                        v-model="formData.borrower.email"
                        @change="changeBorrower('email')"
                      />
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-4">
                      <label for="homePhone" class="form-control-sm text-dark">Home Phone</label>
                      <input
                        type="tel"
                        class="form-control form-control-sm"
                        id="homePhone"
                        placeholder="Home Phone"
                        v-model="formData.borrower.homePhone"
                        @change="changeBorrower('homePhone')"
                        @keyup="formatPhone('homePhone')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="cellPhone" class="form-control-sm text-dark">Cell Phone</label>
                      <input
                        type="tel"
                        class="form-control form-control-sm"
                        id="cellPhone"
                        placeholder="Cell Phone"
                        v-model="formData.borrower.cellPhone"
                        @change="changeBorrower('cellPhone')"
                        @keyup="formatPhone('cellPhone')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="businessPhone" class="form-control-sm text-dark">Business Phone</label>
                      <input
                        type="tel"
                        class="form-control form-control-sm"
                        id="businessPhone"
                        placeholder="Business Phone"
                        v-model="formData.borrower.businessPhone"
                        @change="changeBorrower('businessPhone')"
                        @keyup="formatPhone('businessPhone')"
                      />
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-3">
                      <label for="yearsLineOfWork" class="form-control-sm text-dark">Years Line of Work</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="yearsLineOfWork"
                        placeholder="Years"
                        v-model="formData.borrower.yearsLineOfWork"
                        @change="changeBorrower('yearsLineOfWork')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="yearsOfSchool" class="form-control-sm text-dark">Years of School</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="yearsOfSchool"
                        placeholder="Years of School"
                        v-model="formData.borrower.yearsOfSchool"
                        @change="changeBorrower('yearsOfSchool')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="numberOfDependents" class="form-control-sm text-dark">No of Dependents</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="numberOfDependents"
                        placeholder="Number of Dependents"
                        v-model="formData.borrower.numberOfDependents"
                        @change="changeBorrower('numberOfDependents')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="dependentAges" class="form-control-sm text-dark">Dependent Ages</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="dependentAges"
                        placeholder="Dependent Ages"
                        v-model="formData.borrower.dependentAges"
                        @change="changeBorrower('dependentAges')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'

import Borrower from '@/services/BorrowerService'
import PickDetail from '@/services/PickDetailService'

export default {
  name: 'borrowerModal',
  created: async function () {
    //
    // Load data from the API end points
    //

    this.borrower = this.passedBorrower;

    // Borrower data

    if (this.borrower != null && this.borrower.id > 0) {
      this.borrower = await Borrower.getBorrower(this.borrower.id);
    } else {
      this.borrower.borrowerType = "Co Borrower";
      this.borrower.firstName = "New";
      this.borrower.lastName = "Borrower";
      this.borrower.email = "update.me@gmail.com";
    }
    this.borrower.dob = this.formatDate(this.borrower.dob, "YYYY-MM-DD");
    this.formData.borrower = lodash.cloneDeep(this.borrower);

    // Lists for options

    this.borrowerTypes = await PickDetail.getPickListDetail("borrowertype");
    this.creditRatings = await PickDetail.getPickListDetail("creditrating");
    this.maritalStatuses = await PickDetail.getPickListDetail("maritalstatus");
  },
  data: function () {
    return {
      borrower: {},
      borrowerTypes: [],
      creditRatings: [],
      formData: {
        borrower: {},
      },
      maritalStatuses: [],
    }
  },
  methods: {
    changeBorrower: function (fieldName) {
      if (this.formData.borrower[fieldName] != this.borrower[fieldName]) {
        this.borrower[fieldName] = this.formData.borrower[fieldName];
        this.saveBorrower();
      }
    },
    close: function () {
      this.$emit('close', this.borrower);
    },
    formatDate: function (date, format) {
      var formattedDate = null;

      if (date != null) {
        formattedDate = moment(date.substring(0, 10)).format(format);
      }

      return formattedDate;
    },
    formatPhone: function(fieldName) {
      var value = this.formData.borrower[fieldName];

      //
      // Strip all characters from the input except digits and trim to ten characters
      //
      value = value.replace(/\D/g,'');
      value = value.substring(0,10);

      //
      // Format based on the length of the string
      //
      var size = value.length;

      if (size == 0) {
        // NOP
      } else if (size < 4) {
        value = '(' + value;
      } else if (size < 7) {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6);
      } else {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6) + '-' + value.substring(6,10);
      }

      this.formData.borrower[fieldName] = value;
    },
    formatSSN: function(fieldName) {
      var value = this.formData.borrower[fieldName];

      //
      // Strip all characters from the input except digits and trim to ten characters
      //
      value = value.replace(/\D/g,'');
      value = value.substring(0,9);

      //
      // Format based on the length of the string
      //
      var size = value.length;

      if (size == 0) {
        // NOP
      } else if (size < 5) {
        value = value.substring(0,3) + '-' + value.substring(3,5);
      } else {
        value = value.substring(0,3) + '-' + value.substring(3,5) + '-' + value.substring(5,9);
      }

      this.formData.borrower[fieldName] = value;
    },
    saveBorrower: async function() {
      this.borrower.dob = this.formatDate(this.borrower.dob);

      if (this.borrower.id > 0) {
        delete(this.borrower.created);  // Parsing dates was causing an exception on the server
        delete(this.borrower.modified); // so kill these as they're set by the server anyway
        this.borrower = await Borrower.updateBorrower(this.borrower);
      } else {
        this.borrower = await Borrower.createBorrower(this.borrower);
        this.$emit('addBorrower', this.borrower);
      }

      this.borrower.dob = this.formatDate(this.borrower.dob, "YYYY-MM-DD");
      this.formData.borrower = lodash.cloneDeep(this.borrower);
    },
  },
  props: ['passedBorrower'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
