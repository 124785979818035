<template>
<div class="application-card">
  <div>
      <span class="badge badge-pill"><span>4</span></span><h4 class="application-card-header">Borrower Information</h4>
  </div>
  <div class="card mt-1" id="section4">
    <div class="card-header">
      <div class="row custom-card-sm-header">
        <div class="col-auto mr-auto" v-cloak>
        </div>
        <div class="col-auto custom-cursor-pointer" @click="borrowerModal(null)">
          <i class="fa fa-plus-circle mr-2" aria-hidden="true" />
          <span class="medium">Add Borrower</span>
        </div>
      </div>
    </div>

    <div id="confirmBorrowerDeleteModal">
      <ConfirmModal v-if="showConfirmBorrowerDeleteModal" @confirm="deleteBorrower()" @cancel="showConfirmBorrowerDeleteModal = false">
        <!-- Set these slot data elements to customize the modal -->
        <h3 slot="header">Confirm Delete</h3>
        <p slot="body">
          Are you sure you want to delete the {{borrowerToEdit.borrowerType}} borrower record for {{borrowerToEdit.firstName}} {{borrowerToEdit.lastName}}
        </p>
      </ConfirmModal>
    </div>

    <div id="confirmBorrowerAddressDeleteModal">
      <ConfirmModal v-if="showConfirmBorrowerAddressDeleteModal" @confirm="deleteBorrowerAddress()" @cancel="showConfirmBorrowerAddressDeleteModal = false">
        <!-- Set these slot data elements to customize the modal -->
        <h3 slot="header">Confirm Delete</h3>
        <p slot="body">
          Are you sure you want to delete the {{borrowerAddressData.address.addressType}} borrower address record for {{borrowerAddressData.borrower.firstName}} {{borrowerAddressData.borrower.lastName}}
        </p>
      </ConfirmModal>
    </div>

    <div id="BorrowerAddressModal">
      <BorrowerAddressModal v-if="showBorrowerAddressModal" :borrowerAddressData="borrowerAddressData" @addAddress="addBorrowerAddress" @close="changeBorrowerAddress" />
    </div>

    <div id="BorrowerModal">
      <BorrowerModal v-if="showBorrowerModal" :passedBorrower="borrowerToEdit" @addBorrower="addBorrower" @close="changeBorrower" />
    </div>

    <form class="ml-2 mr-2">
      <div v-for="(borrower, borrowerIdx) in formData.borrowers" :key="borrower.id" class="form-row">
        <div class="form-group col-md-12">
          <div class="form-row ml-2 mr-2">
            <div class="form-group col-md-2">
              <label for="borrowerType" class="form-control-sm">Borrower Type</label>
              <br />
              <label id="borrowerType" class="form-control-sm custom-fg-dark">{{borrower.borrowerType}}</label>
            </div>
            <div class="form-group col-md-3">
              <label for="firstName" class="form-control-sm">First Name</label>
              <br />
              <label id="firstName" class="form-control-sm custom-fg-dark">{{borrower.firstName}}</label>
            </div>
            <div class="form-group col-md-3">
              <label for="lastName" class="form-control-sm">Last Name</label>
              <br />
              <label id="lastName" class="form-control-sm custom-fg-dark">{{borrower.lastName}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="ssn" class="form-control-sm">Social Security Number</label>
              <br />
              <label id="ssn" class="form-control-sm custom-fg-dark">{{borrower.ssn}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="taxesAndInsurance" class="form-control-sm">Taxes and Insurance</label>
              <br />
              <label id="taxesAndInsurance" class="form-control-sm custom-fg-dark">{{borrower.taxesAndInsurance ? 'YES' : 'NO'}}</label>
            </div>
            <div class="form-group custom-cursor-pointer col-md-1 mt-2">
              <i class="fa fa-pencil mr-2" aria-hidden="true" @click="borrowerModal(borrower)" />
              <i class="fa fa-trash" aria-hidden="true" @click="confirmDeleteBorrower(borrower)" />
            </div>
          </div>

          <div class="form-row ml-2 mr-2">
            <div class="form-group col-md-2">
              <label for="dob" class="form-control-sm">Date of Birth</label>
              <br />
              <label id="dob" class="form-control-sm custom-fg-dark">{{formatDate(borrower.dob, "MM/DD/YYYY")}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="martialStatus'" class="form-control-sm">Marital Status</label>
              <br />
              <label id="maritalStatus" class="form-control-sm custom-fg-dark">{{borrower.maritalStatus}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="yearsLineOfWork'" class="form-control-sm">Credit Rating</label>
              <br />
              <label id="yearsLineOfWork" class="form-control-sm custom-fg-dark">{{borrower.creditRating}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="homePhone" class="form-control-sm">Home Phone</label>
              <br />
              <label id="homePhone" class="form-control-sm custom-fg-dark">{{borrower.homePhone}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="cellPhone" class="form-control-sm">Cell Phone</label>
              <br />
              <label id="cellPhone" class="form-control-sm custom-fg-dark">{{borrower.cellPhone}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="businessPhone" class="form-control-sm">Business Phone</label>
              <br />
              <label id="businessPhone" class="form-control-sm custom-fg-dark">{{borrower.businessPhone}}</label>
            </div>
          </div>

          <div class="form-row ml-2 mr-2">
            <div class="form-group col-md-4">
              <label for="email" class="form-control-sm">Email</label>
              <br />
              <label id="email" class="form-control-sm custom-fg-dark">{{borrower.email}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="yearsLineOfWork'" class="form-control-sm">Years Line of Work</label>
              <br />
              <label id="yearsLineOfWork" class="form-control-sm custom-fg-dark">{{borrower.yearsLineOfWork}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="yearsOfSchool" class="form-control-sm">Years of School</label>
              <br />
              <label id="yearsOfSchool" class="form-control-sm custom-fg-dark">{{borrower.yearsOfSchool}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="numberOfDependents" class="form-control-sm">No. Dependents</label>
              <br />
              <label id="email" class="form-control-sm custom-fg-dark">{{borrower.numberOfDependents}}</label>
            </div>
            <div class="form-group col-md-2">
              <label for="dependentAges" class="form-control-sm">Dependent Ages</label>
              <br />
              <label id="email" class="form-control-sm custom-fg-dark">{{borrower.dependentAges}}</label>
            </div>
          </div>

          <div class="row custom-card-sm-header mt-2 mb-2">
            <div class="col-auto mr-auto" v-cloak>
              <p class="small font-italic ml-3">Address Information for {{borrower.firstName}} {{borrower.lastName}}</p>
            </div>
            <div class="col-auto custom-cursor-pointer" @click="borrowerAddressModal(borrowerIdx, null)">
              <i class="fa fa-plus-circle mr-2" aria-hidden="true" />
              <span class="small">Add Address</span>
            </div>
          </div>

          <div v-for="(address, addressIdx) in formData.addresses[borrowerIdx]" :key="address.id" class="form-row">
            <div class="form-group col-md-12">
              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-1 custom-cursor-pointer">
                  <br />
                  <i class="fa fa-pencil mr-2" aria-hidden="true" @click="borrowerAddressModal(borrowerIdx, addressIdx)"/>
                  <i class="fa fa-trash" aria-hidden="true" @click="confirmDeleteBorrowerAddress(borrowerIdx, addressIdx)" />
                </div>
                <div class="form-group col-md-4">
                  <label for="addressType" class="form-control-sm">Address Type</label>
                  <br />
                  <label id="addressType" class="form-control-sm custom-fg-dark">{{address.addressType}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="current" class="form-control-sm">Current</label>
                  <br />
                  <label id="current" class="form-control-sm custom-fg-dark">{{(address.current) ? "Yes" : "No"}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="ownershipStatus" class="form-control-sm">Address Status</label>
                  <br />
                  <label id="ownershipStatus" class="form-control-sm custom-fg-dark">{{address.ownershipStatus}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="yearsAtAddress" class="form-control-sm">Years at address</label>
                  <br />
                  <label id="yearsAtAddress" class="form-control-sm custom-fg-dark">{{address.yearsAtAddress}}</label>
                </div>
              </div>
              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-1">
                  <br />
                </div>
                <div class="form-group col-md-4">
                  <label for="address" class="form-control-sm">Street Address</label>
                  <br />
                  <label id="address" class="form-control-sm custom-fg-dark">{{address.address}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="city" class="form-control-sm">City</label>
                  <br />
                  <label id="city" class="form-control-sm custom-fg-dark">{{address.city}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="stateId" class="form-control-sm">State</label>
                  <br />
                  <label id="stateId" class="form-control-sm custom-fg-dark">{{getStateName(address.stateId)}}</label>
                </div>
                <div class="form-group col-md-2">
                  <label for="zip" class="form-control-sm">Zip</label>
                  <br />
                  <label id="zip" class="form-control-sm custom-fg-dark">{{address.zip}}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'

import BorrowerAddressModal from '@/components/modals/BorrowerAddressModal.vue'
import BorrowerModal from '@/components/modals/BorrowerModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'

import Borrower from '@/services/BorrowerService'
import State from '@/services/StateService'

export default {
  name: 'section4',
  components: {
    BorrowerAddressModal,
    BorrowerModal,
    ConfirmModal
  },
  created: async function () {
    //
    // Load data from the API end points
    //

    // Borrower data

    this.borrowers = await Borrower.getBorrowers(this.applicationId);
    this.formData.borrowers = lodash.cloneDeep(this.borrowers);

    // Borrower address data.  We copy to form data because that seemed to be
    // needed to trigger the reactivity.  Need to explore this further.

    var idx;

    for (idx = 0; idx < this.borrowers.length; ++idx) {
      this.addresses[idx] = await Borrower.getBorrowerAddresses(this.borrowers[idx].id);
    }
    this.formData.addresses = lodash.cloneDeep(this.addresses);

    // Lists for options

    this.states = await State.getStates();
  },
  data: function () {
    return {
      addresses: [],
      borrowers: [],
      borrowerAddressData: {},
      borrowerToEdit: null,
      formData: {
        addresses: [],
        borrowers: [],
      },
      showConfirmBorrowerAddressDeleteModal: false,
      showConfirmBorrowerDeleteModal: false,
      showBorrowerAddressModal: false,
      showBorrowerModal: false,
      states: [],
    }
  },
  methods: {
    addBorrower: async function(borrower) {
      if (borrower != null) {
        this.borrowers.push(borrower);
        this.formData.borrowers = lodash.cloneDeep(this.borrowers);
      }
    },
    addBorrowerAddress: async function(returnAddressData) {
      if (returnAddressData != null) {
        var borrowerIdx = returnAddressData.borrowerIdx;
        var address = returnAddressData.address;

        if (this.addresses[borrowerIdx] == null) {
          this.addresses[borrowerIdx] = [];
        }
        this.addresses[borrowerIdx].push(address);
        this.formData.addresses = lodash.cloneDeep(this.addresses);
      }
    },
    borrowerAddressModal: function(borrowerIdx, addressIdx) {
      this.borrowerAddressData = {
        borrower: this.borrowers[borrowerIdx],
        borrowerIdx: borrowerIdx,
        address: {
          id: 0,
        },
      }

      if (addressIdx != null) {
        this.borrowerAddressData.address = this.addresses[borrowerIdx][addressIdx];
      }

      this.showBorrowerAddressModal = true;
    },
    borrowerModal: function(borrower) {
      if (borrower == null) {
        borrower = {
          id: 0,
          applicationId: this.applicationId,
        }
      }
      this.borrowerToEdit = borrower;
      this.showBorrowerModal = true;
    },
    changeBorrower: function (borrower) {
      if (borrower != null) {
        var idx = lodash.findIndex(this.borrowers, {id: borrower.id});

        this.borrowers[idx] = borrower;
        this.formData.borrowers = lodash.cloneDeep(this.borrowers);
      }

      let primaryBorrower = this.formData.borrowers.find(b => b.borrowerType.toUpperCase() === 'PRIMARY')

      this.$emit("changePrimaryBorrower", lodash.cloneDeep(primaryBorrower));

      this.showBorrowerModal = false;
    },
    changeBorrowerAddress: function (returnAddressData) {
      if (returnAddressData != null) {
        var borrowerIdx = returnAddressData.borrowerIdx;
        var address = returnAddressData.address;
        var idx;

        idx = lodash.findIndex(this.addresses[borrowerIdx], {id: address.id});

        this.addresses[borrowerIdx][idx] = address;
        this.formData.addresses = lodash.cloneDeep(this.addresses);
      }
      this.showBorrowerAddressModal = false;
    },
    confirmDeleteBorrower: function (borrower) {
      this.borrowerToEdit = borrower;
      this.showConfirmBorrowerDeleteModal = true;
    },
    confirmDeleteBorrowerAddress: function (borrowerIdx, addressIdx) {
      this.borrowerAddressData = {
        borrower: this.borrowers[borrowerIdx],
        borrowerIdx: borrowerIdx,
        address: this.addresses[borrowerIdx][addressIdx],
        addressIdx: addressIdx,
      }
      this.showConfirmBorrowerAddressDeleteModal = true;
    },
    deleteBorrower: async function () {
      await Borrower.deleteBorrower(this.borrowerToEdit.id);

      //
      // Lodash "without" wasn't working for some reason, so do it manually
      //
      var idx = lodash.findIndex(this.borrowers, {id: this.borrowerToEdit.id});

      this.borrowers.splice(idx, 1);
      this.formData.borrowers = lodash.cloneDeep(this.borrowers);

      this.showConfirmBorrowerDeleteModal = false;
    },
    deleteBorrowerAddress: async function () {
      await Borrower.deleteBorrowerAddress(this.borrowerAddressData.borrower.id, this.borrowerAddressData.address.id);

      this.addresses[this.borrowerAddressData.borrowerIdx].splice(this.borrowerAddressData.addressIdx, 1);
      this.formData.addresses = lodash.cloneDeep(this.addresses);

      this.showConfirmBorrowerAddressDeleteModal = false;
    },
    formatDate: function (date, format) {
      var formattedDate = null;

      if (date != null) {
        formattedDate = moment(date.substring(0, 10)).format(format);
      }

      return formattedDate;
    },
    getStateName: function (stateId) {
      var ret = "";
      var idx = lodash.findIndex(this.states, {id: stateId});

      if (idx >= 0) {
        ret = this.states[idx].name;
      }

      return ret;
    },
  },
  props: ['applicationId'],
}

</script>
