<template>
  <div class="application-card">
    <div>
        <span class="badge badge-pill"><span>1</span></span><h4 class="application-card-header">Application Details</h4>
    </div>

    <div class="card mt-1" id="details">
      <form class="ml-2 mr-2">
        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="userId" class="form-control-sm">Consultant</label>
              <select class="custom-select custom-select-sm mb-2" id="userId" v-model="formData.application.user.id" @change="assignLoanOfficer()">
                <option
                  v-for="(user) in users"
                  :key="user.id"
                  :value="user.id">{{user.firstName}} {{user.lastName}} ({{user.username}})</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="status" class="form-control-sm">Status</label>
              <select class="custom-select custom-select-sm" id="status" v-model="formData.application.status" @change="setStatus()">
                <option selected :value="formData.application.status">{{formData.application.status}}</option>
                <option
                  v-for="(status) in statuses.filter(s => s.active).filter(s => s.value !== formData.application.status)"
                  :key="status.id"
                  :value="status.value">{{status.value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="status" class="form-control-sm">Second Status</label>
              <select class="custom-select custom-select-sm" id="secondStatus" v-model="formData.application.secondStatus" @change="setSecondStatus()">
                <option selected :value="formData.application.secondStatus">{{formData.application.secondStatus}}</option>
                <option
                    v-for="(secondStatus) in secondStatuses.filter(s => s.active).filter(s => s.value !== formData.application.secondStatus)"
                    :key="secondStatus.id"
                    :value="secondStatus.value">{{secondStatus.value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="status" class="form-control-sm">Lead Source</label>
              <select class="custom-select custom-select-sm" id="leadSource" v-model="formData.application.lead.leadSource" @change="setLeadSource()">
                <option
                    v-for="(leadSource) in leadSources"
                    :key="leadSource.id"
                    :value="leadSource.value">{{leadSource.value}}</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="promotionCode" class="form-control-sm">Promotion Code</label>
              <select class="custom-select custom-select-sm" id="promotionCode" v-model="formData.application.promotionCode" @change="setPromotionCode()">
                <option
                  v-for="(promotionCode) in promotionCodes"
                  :key="promotionCode.id"
                  :value="promotionCode.promotionCode">{{promotionCodeValue(promotionCode)}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="vendorLeadId" class="form-control-sm">Vendor Lead Id</label>
              <br />
              <label v-if="formData.application.lead !== undefined" id="legacyId" class="custom-fg-dark">{{defaultToUnset(formData.application.lead.vendorLeadId)}}</label>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="filterId" class="form-control-sm">Filter Id</label>
              <br />
              <label v-if="formData.application.lead !== undefined" id="legacyId" class="custom-fg-dark">{{defaultToUnset(formData.application.lead.filterId)}}</label>
            </div>
          </div>
          <div class="form-group col-md-5">
            <div class="form-group">
              <label for="leadDescription" class="form-control-sm">Lead Description</label>
              <br />
              <label v-if="formData.application.lead !== undefined" id="legacyId" class="custom-fg-dark">{{defaultToUnset(formData.application.lead.leadDescription)}}</label>
            </div>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4">
              <div class="form-group">
                <label for="simpleNexusStatus" class="form-control-sm">SimpleNexus Status</label>
                <br />
                <label id="simpleNexusStatus" class="custom-fg-dark">{{defaultToUnset(formData.application.simpleNexusStatus)}}</label>
              </div>
          </div>
          <div class="form-group col-md-4">
            <div class="form-group">
              <label for="simpleNexusGUID" class="form-control-sm">SimpleNexus GUID</label>
              <br />
              <!-- Link to SimpleNexus Loan Officer admin login -->
              [<a href="https://simplenexus.com/servicer_loans/" id="simpleNexusGUID" class="custom-fg-dark">{{simpleNexusLinkText(formData.application.simpleNexusGUID)}}</a>]
            </div>
          </div>
          <div class="form-group col-md-4">
            <div v-if="formData.application.simpleNexusStatus === 'NEW'" class="form-group">
              <button class="btn btn-primary mt-2" @click.prevent="submitSimpleNexus()">Submit to SimpleNexus</button>
            </div>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-12">
            <div class="form-group">
              <label for="customerComments" class="form-control-sm">Customer Comments</label>
              <br />
              <label v-if="formData.application.lead !== undefined" id="customerComments" class="custom-fg-dark">{{defaultToUnset(formData.application.lead.customerComment)}}</label>
            </div>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-3">
            <div class="form-group">
              <label for="investor" class="form-control-sm">Lender</label>
              <br />
              <label id="investor" class="custom-fg-dark">{{formData.application.investor}}</label>
            </div>
          </div>
          <div class="form-group col-md-5">
            <div class="form-group">
              <label for="product" class="form-control-sm">Product</label>
              <br />
              <label id="product" class="custom-fg-dark">{{formData.application.product}}</label>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="created" class="form-control-sm">Created</label>
              <br />
              <label id="created" class="custom-fg-dark">{{formatDate(formData.application.created, "MM/DD/YYYY")}}</label>
            </div>
          </div>
          <div class="form-group col-md-2">
            <div class="form-group">
              <label for="modified" class="form-control-sm">Modified</label>
              <br />
              <label id="modified" class="custom-fg-dark">{{formatDate(formData.application.modified, "MM/DD/YYYY")}}</label>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import Application from '@/services/ApplicationService'
import PickDetail from '@/services/PickDetailService'
import PromotionCode from '@/services/PromotionCodeService'
import User from '@/services/UserService'

export default {
  name: 'detailsSection',
  computed: {
    truncatedLegacyId: function() {
      var ret = "";

      if (this.formData.application.legacyId != null) {
        var len = this.formData.application.legacyId.length;
        var val = this.formData.application.legacyId.substring(len - 8, len);

        ret = 'XXXXX' + val;
      }

      return ret;
    },
  },
  created: async function () {
    // Get our application information and set in the form data
    this.application = await Application.getApplication(this.applicationId);
    this.formData.application = lodash.cloneDeep(this.application);
    if (typeof(this.formData.application.lead) == 'undefined') {
      this.formData.application.lead = {}
    }

    this.formData.application.simpleNexusStatus = this.application.simpleNexusStatus;
    this.formData.application.simpleNexusGUID = this.application.simpleNexusGUID;

    this.formData.application.created = this.formatDate(this.application.created, "MM/DD/YYYY");
    this.formData.application.modified = this.formatDate(this.application.modified, "MM/DD/YYYY");

    // For pick lists
    this.promotionCodes = await PromotionCode.getPromotionCodes();
    this.statuses = await PickDetail.getPickListDetail('applicationstatus');
    this.secondStatuses = await PickDetail.getPickListDetail('secondapplicationstatus');
    this.leadSources = await PickDetail.getPickListDetail('leadsource')
    this.users = await User.getUserList();

    // Add a blank for the promotion codes
    var blankPromotionCode = {
      value: '',
    };
    this.promotionCodes.unshift(blankPromotionCode);
  },
  data: function () {
    return {
      application: {},
      formData: {
        application: {},
        lead: {},
      },
      lead: {},
      promotionCodes: [],
      statuses: [],
      secondStatuses: [],
      leadSources: [],
      users: [],
    }
  },
  methods: {
    setStatus: async function() {
      this.application = await Application.setStatus(this.applicationId, this.formData.application['status']);
      this.formData.application.status = this.application.status;
    },
    setSecondStatus: async function() {
      this.application = await Application.setSecondStatus(this.applicationId, this.formData.application['secondStatus']);
      this.formData.application.secondStatus = this.application.secondStatus;
    },
    setLeadSource: async function() {
      this.application = await Application.setLeadSource(this.applicationId, this.formData.application.lead['leadSource']);
      this.formData.application.lead.leadSource = this.application.lead.leadSource;
    },
    setPromotionCode: async function() {
      this.application = await Application.setPromotionCode(this.applicationId, this.formData.application['promotionCode']);
      this.formData.application.promotionCode = this.application.promotionCode;
    },
    defaultToUnset: function(value) {
      var ret = "";

      if (value != null) {
        ret = value;
      }

      return ret;
    },
    simpleNexusLinkText: function(value) {
      if (value === '' || value === undefined) {
        return 'SimpleNexus Servicer Portal'
      }
      return value;
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatDate: function (date, format) {
      var ret = date;

      if (date != null) {
        ret = moment(date.substring(0, 10)).format(format);
      }

      return ret;
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.000%');
    },
    promotionCodeValue: function (promotionCode) {
      if (promotionCode.value !== '') {
        return promotionCode.promotionCode + " (Discount Amount: " + this.formatCurrency(promotionCode.discountAmount) + ")";
      }
    },
    submitSimpleNexus: async function() {
      this.application = await Application.resubmitSimpleNexus(this.application.id);
      this.formData.application.simpleNexusStatus = this.application.simpleNexusStatus;
      this.formData.application.simpleNexusGUID = this.application.simpleNexusGUID;
    },
    assignLoanOfficer: async function() {
      await Application.assignLoanOfficer(this.applicationId, this.formData.application.user.id);
      this.$emit("loan-officer");
    }
  },
  props: ['applicationId'],
}
</script>
