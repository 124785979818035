<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header font-weight-bold">
            <slot name="header">Rate Quote Email to: {{ borrower.email }}</slot>
            <div v-if="showEmailRateQuoteError" class="col error">
              {{this.emailRateQuoteError.subject}}: {{this.emailRateQuoteError.description}}
            </div>
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary mt-2" :disabled="sendEmailClicked" @click.prevent="sendEmailRateQuote">Send</button>
                  </div>
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div style="margin: 20px 0px;box-sizing:border-box;">
              <textarea name="" id="borrower-msg" v-model="borrowerMsg" cols="30" rows="4" placeholder="Add personalized message to borrower here..." style="box-sizing:border-box;width:100%;background-color:#f1f7ed;border: 3px rgb(0,150,70) solid;resize: none;border-radius: 5px;padding: 5px;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-weight: bold;font-size: 14px;"></textarea>
            </div>
            <div style="display: inline-block;">
              <table align="center" style="width: 100%;  padding-left:10px; padding-right:10px;margin-bottom: 20px;">
                <tr>
                  <td>
                    <table align="center" style="width: 100%;border-bottom: 2px; border-top: 0px; border-left: 0px; border-right: 0px; border-style: solid; border-color: #40934f;">
                      <tr style="text-align: end;font-size: 2em;color: #40934f;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                        <td>Initial Fees Summary</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <table align="center" style="width: 100%;  padding-left:10px; padding-right:10px;">
                <tr>
                  <td style="width: 23%;">
                    <div style="width: 275px;">
                      <img src="../../assets/RR_Logo.png" style="display: inline-block;max-width: 100%;">
                    </div>
                  </td>
                  <td>
                    <div style="display: inline-block;">
                      <div style="margin: 0 0 0;">
                        <span style="font-size: 18px;">
                          <strong>{{ loanOfficer.firstName + ' ' + loanOfficer.lastName }}</strong>
                        </span>
                      </div>
                      <div style="margin: 0 0 20px;">
                        <span style="font-size: 18px;">
                          <strong>{{ ((loanOfficer.loanOfficerTitle) ? loanOfficer.loanOfficerTitle : '') }}</strong>
                        </span>
                      </div>
                      <div>
                        <span style="font-size: 18px;"><strong>Phone: </strong>{{ loanOfficer.phoneNumber }}</span>
                      </div>
                      <div>
                        <span style="font-size: 18px;"><strong>Email: </strong>{{ loanOfficer.email }}</span>
                      </div>
                      <div>
                        <!--
                          Dirty hack: identify the corporate Rate Rabbit user by the specific first+last name
                          to display Corp NMLS, all other users get plain NMLS
                          -->
                        <span style="font-size: 18px;"><strong>{{ (loanOfficer.firstName === 'Rate Rabbit' && loanOfficer.lastName === 'Home Loans') ? 'Corporate NMLS#: ' : 'NMLS#: ' }} </strong>{{ loanOfficer.nmlsNumber }}</span>
                      </div>
                    </div>
                  </td>
                  <td style="text-align: right;">
                    <div style="display: inline-block;">
                      <div style="margin: 0 0 20px;">
                        <span style="font-size: 18px;"><strong>{{ borrower.firstName + ' ' + borrower.lastName }}</strong></span>
                      </div>
                      <div v-if="property && property.address && property.address.address && property.address.state">
                        <span style="font-size: 18px;color: #40934f;"><strong>Subject Property Address:</strong></span>
                        <br/>
                        <span style="font-size: 18px;">{{property.address.address }}</span>
                        <br/>
                        <span style="font-size: 18px;">{{ property.address.city + ', ' + property.address.state.shortName + ' ' + property.address.zip }}</span>
                        <br/>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <table align="center" style="width: 100%; padding-left:10px; padding-right:10px;">
                <tbody>
                <tr style="padding-bottom: 10px;">
                  <td style="font-size: 18px;color: #111111;font-weight: 600;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; padding-top: 15px;">
                    This is not a rate commitment. Your rate commitment will be honored when you instruct us to lock your loan and move forward with the loan process.
                  </td>
                </tr>
                <tr style="padding-bottom: 10px;">
                  <td style="padding-top: 15px">
                    <p><span>{{ borrowerMsg }}</span></p>
                  </td>
                </tr>
                </tbody>
              </table>

              <div style="padding-top: 40px;">
                <table align="center" cellpadding="5" style="width: 100%;padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Your {{ loan.loanPurpose }}  Quote Details: {{ loan.numberOfMonths / 12 }}-Year {{ loan.amortizationType }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" style="width: 100%;padding-left:10px; padding-right:10px;">
                      <tr>
                        <td>
                          <table align="left" cellpadding="5"
                                 cellspacing="0" style="width: 49.50%;border-collapse: separate;border-spacing: 0 10px;">
                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Interest Rate</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.interestRate | percentage }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>APR</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.apr | percentage }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Assumed Credit Score</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ borrower.creditRating }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Property Use</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ property.propertyUse }}</span>
                                </div>
                              </td>
                            </tr>
                          </table>
                          <table align="right" cellpadding="5"
                                 cellspacing="0" style="width: 49.50%;border-collapse: separate;border-spacing: 0 10px;">
                            <tr v-if="!isBreakdown" style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Monthly Payment</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.mortgagePayment | currency('$0,0.00') }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="isCashOutAmountPresent" style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Cash Out Amount</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ refinanceLoan.cashOutAmount | currency('$0,0.00') }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="isBreakdown" style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Principal and Interest Payment</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ principalAndInterestPayment(loan.mortgagePayment, loan.mortgageInsurance) | currency('$0,0.00') }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="isBreakdown" style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Mortgage Insurance</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.mortgageInsurance | currency('$0,0.00') }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="isBreakdown" style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Total Payment with Mortgage Insurance</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.mortgagePayment | currency('$0,0.00') }}</span>
                                </div>
                              </td>
                            </tr>

                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 15px 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';"><strong>Loan Amount</strong></span>
                              </td>
                              <td style="text-align: end; padding: 15px 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ loan.amount | currency }}</span>
                                </div>
                              </td>
                            </tr>
                            <tr style="background-color: #e5f4ec;">
                              <td style="text-align: start; padding: 10px 15px;float: left;">
                                <div style="float:left;font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                                  <strong>Assumed Appraised Value</strong
                                  ></div>
                              </td>
                              <td style="text-align: end; padding: 10px 15px;">
                                <div style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                                  {{ this.assumedAppraisedValue }}
                                </div>
                                <br>
                                <div>
                                  {{ this.formatPercentage(this.loan.ltvPercent) }} Loan-to-Value (LTV)
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>
              <table align="center" style="width: 100%; padding-left:10px; padding-right:10px;" cellspacing="5">
                <tr v-if="loan.credit && loan.credit > 0">
                  <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                    <div style="display: inline-flex;">
                      <span style="padding-bottom: 10px;padding-left: 10px;">Rate Rabbit Home Loans Credit</span>
                    </div>
                  </td>

                  <td style="padding: 15px 10px;text-align: end;">
                    <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      ({{ loan.credit | currency }})</span>
                  </td>
                </tr>
                <tr v-if="this.promotionCode && Object.keys(this.promotionCode).length > 0">
                  <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                    <div style="display: inline-flex;">
                      <span style="padding-bottom: 10px;padding-left: 10px;">Promotional Code ({{ this.promotionCode.promotionCode + ': ' + promotionCode.partnerName }})</span>
                    </div>
                  </td>

                  <td style="padding: 15px 10px;text-align: end;">
                    <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      ({{ this.promotionCode.discountAmount | currency }})</span>
                  </td>
                </tr>
              </table>
              <table align="center" style="width: 100%; padding-left:10px; padding-right:10px;" cellspacing="5">
                <tr v-if="(loan.credit && loan.credit > 0) || (this.promotionCode && Object.keys(this.promotionCode).length > 0)">
                  <td style="font-size: 26px;color: #40934f;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-top: 30px;">
                    <div style="display: inline-flex; align-items: center;">
                      <img width="40" src="../../assets/verified-logo.png">
                      &nbsp;<span>One Fee Guarantee</span>
                    </div>
                  </td>
                  <td style="text-align: end; padding-top: 30px;">
                    <span style="font-size: 26px;text-decoration: line-through;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      {{ loan.fee | currency }}</span>
                  </td>
                  <td style="padding-top: 30px; text-align: end;">
                    <span style="font-size: 26px; text-align: center; color: #539b60;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      {{ newFee | currency }}</span>
                  </td>
                </tr>
                <tr v-else>
                  <td style="font-size: 26px;color: #40934f;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-top: 30px;">
                    <div style="display: inline-flex; align-items: center;">
                      <img width="40" src="../../assets/verified-logo.png">
                      &nbsp;<span>One Fee Guarantee</span>
                    </div>
                  </td>
                  <td></td>
                  <td style="padding-top: 30px; text-align: end;">
                    <span style="font-size: 26px; text-align: center; color: #539b60;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                      {{ newFee | currency }}</span>
                  </td>
                </tr>
              </table>
              <div>
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Lender Fees:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5" cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Origination Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Processing Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Underwriting Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Closing Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Lender Charges</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 5px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>

              <div style="padding-top: 80px;">
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Required Validation Fees:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5"
                           cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr v-if="loan.loanPurpose !== 'Purchase'">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                            Appraisal Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">
                              Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Tax Services Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Food Certificate Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Credit Report Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Required Validation Charges</span>
                        </td>
                        <td style="text-align: end;">
                          <div style="padding: 15px 5px;display: inline-flex; align-items: center;">
                            <img style="margin-right: 5px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>

              <div style="padding-top: 80px;">
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Title Insurance and Settlement Fees:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5" cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Title Insurance and Search Fee</span>
                        </td>
                        <td v-if="!isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                        <td v-if="isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="padding-left:10px;font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">TBD</span>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Title Insurance and Settlement Charges</span>
                        </td>
                        <td v-if="!isPurchase" style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 5px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                        <td v-if="isPurchase" style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">TBD</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>
              <div style="padding-top: 80px;">
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Attorney and Escrow Fees:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5" cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Attorney Fee</span>
                        </td>
                        <td v-if="!isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                        <td v-if="isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">TBD</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Escrow Fee</span>
                        </td>
                        <td v-if="!isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                        <td v-if="isPurchase" style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">TBD</span>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Attorney and Escrow Charges</span>
                        </td>
                        <td v-if="!isPurchase" style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 5px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                        <td v-if="isPurchase" style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">TBD</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>
              <div style="padding-top: 80px; padding-bottom: 80px;">
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding:15px 10px;padding-left: 10px;">Recording and Government Fees:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5" cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Recording Fee</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;"> {{ (loan.loanPurpose === 'Purchase') ? 'TBD' : 'Included' }}</span>                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Transfer Taxes</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 10px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">{{ (loan.loanPurpose === 'Purchase') ? 'TBD' : 'Included' }}</span>                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Recording and Government Charges</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <img style="margin-right: 5px;" width="25" src="../../assets/verified-logo.png">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-right:10px;">Included</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>

              <div>
                <table align="center" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td>
                      <table align="center" cellpadding="5" style="width: 100%;border: black solid thin;border-bottom: none;border-spacing: 0;">
                        <tr>
                          <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-image: linear-gradient(to right, #72b449 , #42954f);">
                            <div style="display: inline-flex;">
                              <span style="padding-left: 10px;">Total Closing Cost Summary:</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <table align="center" cellpadding="5" cellspacing="0" style="width:100%;border:black solid thin;border-top:none;">
                            <tr v-if="loan.credit && loan.credit > 0">
                              <td style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; padding-left: 15px;">
                                <div style="display: inline-flex;">
                                  <span style="padding-bottom: 10px;">Rate Rabbit Home Loans Credit</span>
                                </div>
                              </td>

                              <td style="text-align: end;" colspan="2">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; padding-right: 15px; ">
                                  ({{ loan.credit | currency }})</span>
                              </td>
                            </tr>
                            <tr v-if="promotionCode && Object.keys(this.promotionCode).length > 0">
                              <td style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; padding-left: 15px;">
                                <div style="display: inline-flex;">
                                  <span style="padding-bottom: 10px;">Promotional Code ({{ promotionCode.promotionCode + ': ' + promotionCode.partnerName }})</span>
                                </div>
                              </td>

                              <td style="text-align: end;" colspan="2">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; padding-right: 15px; ">
                                  ({{ promotionCode.discountAmount | currency }})</span>
                              </td>
                            </tr>
                            <tr v-if="(loan.credit && loan.credit > 0) || (this.promotionCode && Object.keys(this.promotionCode).length > 0)">
                              <td style="padding: 15px 10px;font-size: 18px;color: #40934f;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-top: 30px; padding-left: 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <img width="25" src="../../assets/verified-logo.png">
                                  <span style="margin-left: 5px;">One Fee Guarantee</span>
                                </div>
                              </td>
                              <td style="text-align: end; padding-top: 30px;">
                                <span style="font-size: 18px;text-decoration: line-through;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                                  {{ loan.fee | currency }}</span>
                              </td>
                              <td style="padding-top: 30px; text-align: end; padding-right: 15px;">
                                <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                                  {{ newFee | currency }}</span>
                              </td>
                            </tr>
                            <tr v-else>
                              <td style="padding: 15px 10px;font-size: 18px;color: #40934f;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';padding-top: 30px; padding-left: 15px;">
                                <div style="display: inline-flex; align-items: center;">
                                  <img width="25" src="../../assets/verified-logo.png">
                                  <span style="margin-left: 5px;">One Fee Guarantee</span>
                                </div>
                              </td>
                              <td></td>
                              <td style="padding-top: 30px; text-align: end; padding-right: 15px;">
                                    <span style="font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">
                                      {{ newFee | currency }}</span>
                              </td>
                            </tr>
                          </table>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>

              <div style="padding-top: 80px;">
                <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                  <tr>
                    <td style="padding: 15px 10px;font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; background-color: #e5f4ec;">
                      <div style="display: inline-flex;">
                        <span style="padding-left: 10px;">Prepaids:</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <table align="center" cellpadding="5"
                           cellspacing="0" style="width: 100%; padding-left:10px; padding-right:10px;">
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Daily Interest (15) Days @ ({{ dailyInterest | currency('$0,0.00') }})</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end; padding-left: 10px;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ totalInterest | currency('$0,0.00') }}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Property Insurance</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">TBD</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-left:10px;padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Property Taxes</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 400;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">TBD</span>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color: #e5f4ec;">
                        <td style="padding: 15px 10px;text-align: start; padding-left: 10px;">
                          <span style="padding-left:10px;font-size: 18px; text-align: center; color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Total Estimated Prepaids</span>
                        </td>
                        <td style="padding: 15px 10px;text-align: end;">
                          <div style="display: inline-flex; align-items: center;">
                            <span style="font-size: 18px;color: #111111;font-weight: 700;font-family: 'Open Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">{{ (totalInterest) | currency('$0,0.00') }}</span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </tr>
                </table>
              </div>
              <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                <tr>
                  <td>
                    <h2 style="color: #40934f;margin-bottom: 5px;">You’re almost there!</h2>
                    <p style="font-size: 18px;margin-top:5px;">
                      Next step will be to complete an application and confirm all further details regarding your loan. After that, a loan consultant will reach out to you to lock in your rate and get the process moving for you.
                    </p>
                  </td>
                </tr>
              </table>
              <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                <tr>
                  <td>
                    <div style="text-align: center;margin: 10px 0;">
                      <a href="" style="pointer-events: none; text-decoration: none;padding: 30px 70px;display: inline-block;font-weight: 400;color: white;text-align: center;vertical-align: middle;font-size: 32px;line-height: 1.25;border-radius: 45.5px;background-color: #197b30;border-color: #197b30;font-family: 'Open Sans Condensed', sans-serif;">Continue Application</a>
                    </div>
                  </td>
                </tr>
              </table>
              <table align="center" cellpadding="5" style="width: 100%; padding-left:10px; padding-right:10px;">
                <tr>
                  <td>
                    <img  width="70px" src="../../assets/footer_logo.png" style="margin-right: 10px;">
                  </td>
                  <td>
                    <p style="font-size: 18px;margin: 0;">
                      <strong>Disclaimer:</strong>For information purpose only. Intended for electronic distribution only. This is not a commitment to lend.
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary mt-2" @click.prevent="sendEmailRateQuote">Send</button>
                  </div>
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import '@/filters/TextFilters'
import numeral from "numeral";
import Application from "@/services/ApplicationService";

export default {
  name: 'emailRateQuoteModal',
  data: function () {
    return {
      dailyInterest: 0,
      totalInterest: 0,
      borrowerMsg: "",
      showEmailRateQuoteError: false,
      sendEmailClicked: false
    }
  },
  created: async function () {
    // calculate the daily interest rate
    let interestRate = this.loan.interestRate;
    if (interestRate > 1) {
      interestRate = interestRate / 100;
    }
    this.dailyInterest = this.round((this.loan.amount * interestRate) / 365);
    this.totalInterest = this.round((this.dailyInterest * 15));
  },
  computed: {
    assumedAppraisedValue: function() {
      if (this.loan.loanPurpose === 'Purchase') {
        return this.property.purchasePrice;
      }
      else {
        return this.property.value;
      }
    },
    isBreakdown: function() {
      if (!this.loan.ltvPercent) {
        return false;
      }
      if (this.loan.ltvPercent > 0.8) {
        if (this.loan.mortgageInsurance != null) {
          return true;
        }
      }

      return false;
    },
    isPurchase: function() {
      return this.loan.loanPurpose === "Purchase";
    },
    isCashOutAmountPresent: function() {
      return this.loan.loanPurpose === 'Refinance Cash Out' && this.refinanceLoan && this.refinanceLoan.cashOutAmount
    }
  },
  methods: {
    close: function () {
      this.$emit('cancel')
    },
    sendEmailRateQuote: async function () {
      this.sendEmailClicked = true;

      let emailResponse = await Application.emailRateQuoteForId(this.applicationId, this.borrowerMsg);

      if (emailResponse.status) {
        this.showEmailRateQuoteError = false;
        this.$emit('email-rate-quote');
      }
      else {
        this.showEmailRateQuoteError = true;
        this.emailRateQuoteError = emailResponse.result;
      }

      this.sendEmailClicked = false;
    },
    principalAndInterestPayment: function(total, mortgageInsurance) {
      return total - mortgageInsurance;
    },
    round: function (num) {
      return +(Math.round(num + "e+2")  + "e-2");
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.00%');
    },
  },
  // passed from Section2.vue modal div
  props: ['applicationId', 'loan', 'borrower', 'property', 'loanOfficer', 'newFee', 'promotionCode', 'refinanceLoan'],
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1200px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  max-height: 900px;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
