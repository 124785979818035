<template>
<div class="application-card">
  <div>
      <span class="badge badge-pill"><span>3</span></span><h4 class="application-card-header">Property Information</h4>
  </div>
  <div class="card mt-2" id="section3">
    <form class="ml-2 mr-2">

      <div class="form-row ml-2 mr-2">
        <div class="col-md-12">
          <label for="propertyAddress" class="form-control-sm">Subject Property Address</label>
        </div>
      </div>
      <div class="form-row ml-2 mr-2">
        <div class="col-md-12">
          <input
            type="text"
            class="form-control form-control-sm"
            id="address"
            placeholder="Address"
            v-model="formData.propertyAddress.address"
            @change="setPropertyAddressString" />
        </div>
      </div>

      <div class="form-row ml-2 mr-2">
        <div class="form-group col-md-4">
          <label for="city" class="form-control-sm">City</label>
          <input
            type="text"
            disabled
            class="form-control form-control-sm"
            id="city"
            placeholder="City"
            v-model="formData.propertyAddress.city"
            @change="setPropertyAddressCity"/>
        </div>
        <div class="form-group col-md-4">
          <div class="form-group">
            <label for="stateId" class="form-control-sm">State</label>
            <select
              disabled
              class="custom-select custom-select-sm"
              id="stateId"
              v-model="formData.propertyAddress.stateId"
              @change="setPropertyAddressState">
              <option v-for="(state) in states" :key="state.id" :value="state.id">{{state.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="zip" class="form-control-sm">Zip</label>
          <input
            type="text"
            disabled
            class="form-control form-control-sm"
            id="zip"
            placeholder="Zip"
            v-model="formData.propertyAddress.zipCode"
            @change="setPropertyAddressZip"
          />
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import Address from '@/services/AddressService'
import Application from '@/services/ApplicationService'
import ConstructionLoan from '@/services/ConstructionLoanService'
import Loan from '@/services/LoanService'
import PickDetail from '@/services/PickDetailService'
import Property from '@/services/PropertyService'
import RefinanceLoan from '@/services/RefinanceLoanService'
import State from '@/services/StateService'

export default {
  name: 'section3',
  created: async function () {
    // Load data from the API end points
    this.application = await Application.getApplication(this.applicationId);

    // Loan
    this.loan = this.application.loan;
    this.formData.loan = lodash.cloneDeep(this.loan);

    if (this.loan) {
      this.formData.loan.amount = this.formatCurrency(this.loan.amount);

      // Construction Loan
      if (this.loan.constructionLoanId !== undefined && this.loan.constructionLoanId > 0) {
        this.constructionLoan = await ConstructionLoan.getConstructionLoan(this.loan.constructionLoanId);
        this.formData.constructionLoan = lodash.cloneDeep(this.constructionLoan);

        this.formData.constructionLoan.originalCost = this.formatCurrency(this.constructionLoan.originalCost);
        this.formData.constructionLoan.existingLiens = this.formatCurrency(this.constructionLoan.existingLiens)
        this.formData.constructionLoan.lotPresentValue = this.formatCurrency(this.constructionLoan.lotPresentValue);
        this.formData.constructionLoan.improvementCost = this.formatCurrency(this.constructionLoan.improvementCost);
      }
      this.calculateConstructionTotal();

      // Refinance Loan
      if (this.loan.refinanceLoanId !== undefined && this.loan.refinanceLoanId > 0) {
        this.refinanceLoan = await RefinanceLoan.getRefinanceLoan(this.loan.refinanceLoanId);
        this.formData.refinanceLoan = lodash.cloneDeep(this.refinanceLoan);

        this.formData.refinanceLoan.originalCost = this.formatCurrency(this.refinanceLoan.originalCost);
        this.formData.refinanceLoan.existingLiens = this.formatCurrency(this.refinanceLoan.existingLiens);
        this.formData.refinanceLoan.improvementCost = this.formatCurrency(this.refinanceLoan.improvementCost);
      }
    }

    // Property
    if (this.application.property !== undefined) {
      this.property = this.application.property;
      this.formData.property = lodash.cloneDeep(this.property);

      this.formData.property.leaseholdExpiration = this.formatDate(this.property.leaseholdExpiration, "YYYY-MM-DD")
      this.formData.property.value = this.formatCurrency(this.property.value);
      this.formData.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);

      // Property Address
      this.propertyAddress = this.property.address;

      this.formData.propertyAddress = lodash.cloneDeep(this.propertyAddress);
      this.formData.propertyAddress.stateId = this.property.address.state.id;
      this.formData.propertyAddress.zipCode = this.property.address.zip;
    }

    // Lists for options
    this.estateHoldings = await PickDetail.getPickListDetail("estateholding");
    this.states = await State.getStates();
  },
  data: function() {
    return {
      application: {},
      constructionLoan: {
        id: 0,
      },
      estateHoldings: [],
      formData: {
        constructionLoan: {
          id: 0,
          totalCost: 0,
        },
        loan: {},
        property: {},
        propertyAddress: {},
        refinanceLoan: {
          id: 0,
        },
      },
      loan: {},
      property: {},
      propertyAddress: {},
      propertyUses: [],
      refinanceLoan: {
        id: 0,
      },
      states: [],
      trueFalseValues: [
        {
          id: "false",
          value: "No",
        },
        {
          id: "true",
          value: "Yes",
        },
      ],
    }
  },
  methods: {
    calculateConstructionTotal: function() {
      let lpv = 0;
      let ic = 0;

      if (this.formData.constructionLoan != null && this.formData.constructionLoan.lotPresentValue != null) {
        lpv = this.formData.constructionLoan.lotPresentValue.replace(/\D/g, '');
      }
      if (this.formData.constructionLoan != null && this.formData.constructionLoan.improvementCost != null) {
        ic = this.formData.constructionLoan.improvementCost.replace(/\D/g, '');
      }

      const sum = (+lpv) + (+ic);

      this.formData.constructionLoan.totalCost = this.formatCurrency(sum);
    },
    changeLoan: function (fieldName) {
      if (this.formData.loan[fieldName] !== this.loan[fieldName]) {
        this.loan[fieldName] = this.formData.loan[fieldName];
        this.saveLoan();
      }
    },
    changeProperty: function (fieldName) {
      if (this.formData.property[fieldName] !== this.property[fieldName]) {
        this.property[fieldName] = this.formData.property[fieldName];
        this.saveProperty();
      }
    },
    changePropertyAddress: function (fieldName) {
      if (this.formData.propertyAddress[fieldName] !== this.propertyAddress[fieldName]) {
        this.propertyAddress[fieldName] = this.formData.propertyAddress[fieldName];
        this.savePropertyAddress();
      }
    },
    changeRefinanceLoan: function (fieldName) {
      if (this.formData.refinanceLoan[fieldName] !== this.refinanceLoan[fieldName]) {
        this.refinanceLoan[fieldName] = this.formData.refinanceLoan[fieldName];
        this.saveRefinanceLoan();
      }
    },
    setPropertyAddressString: async function () {
      this.propertyAddress = await Property.setAddress(this.property.id, this.formData.propertyAddress.address);
    },
    setPropertyAddressCity: async function () {
      this.propertyAddress = await Property.setCity(this.property.id, this.formData.propertyAddress.city);
    },
    setPropertyAddressState: async function () {
      this.propertyAddress = await Property.setState(this.property.id, this.formData.propertyAddress.stateId);
    },
    setPropertyAddressZip: async function () {
      this.propertyAddress = await Property.setZipCode(this.property.id, this.formData.propertyAddress.zipCode);
    },
    convertStringToCurrency: function (val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val !== "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    formatCurrency: function (amount) {
      var amt = amount;
      if (!amount) {
        amt = 0;
      }
      return numeral(amt).format('$0,0');
    },
    formatDate: function (date, format) {
      var formattedDate = null;

      if (date != null) {
        formattedDate = moment(date.substring(0, 10)).format(format);
      }

      return formattedDate;
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.00%');
    },
    saveLoan: async function () {
      delete(this.loan.created);  // Parsing dates was causing an exception on the server
      delete(this.loan.modified); // so kill these as they're set by the server anyway
      this.loan.amount = this.convertStringToCurrency(this.loan.amount);

      this.loan = await Loan.updateLoan(this.loan);

      this.formData.loan.amount = this.formatCurrency(this.loan.amount);
    },
    saveProperty: async function () {
      if (this.property !== undefined) {
        delete (this.property.created);  // Parsing dates was causing an exception on the server
        delete (this.property.modified); // so kill these as they're set by the server anyway

        this.property.leaseholdExpiration = this.formatDate(this.property.leaseholdExpiration);
        this.property.value = this.convertStringToCurrency(this.property.value);
        this.property.purchasePrice = this.convertStringToCurrency(this.property.purchasePrice);

        this.property = await Property.updateProperty(this.property);

        this.formData.property.value = this.formatCurrency(this.property.value);
        this.formData.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);
      }
    },
    savePropertyAddress: async function () {
      delete(this.propertyAddress.created);  // Parsing dates was causing an exception on the server
      delete(this.propertyAddress.modified); // so kill these as they're set by the server anyway
      this.propertyAddress = await Address.updateAddress(this.propertyAddress);
    },
    saveRefinanceLoan: async function () {
      if (this.refinanceLoan.id > 0) {
        //
        // We had a refinance loan record, so update
        //
        delete(this.refinanceLoan.created);  // Parsing dates was causing an exception on the server
        delete(this.refinanceLoan.modified); // so kill these as they're set by the server anyway
        this.refinanceLoan = await RefinanceLoan.updateRefinanceLoan(this.refinanceLoan);
      } else {
        //
        // We did not have a refinance loan record, so create one.
        // Be sure to update the loan record with the foreign key.
        //
        this.refinanceLoan = await RefinanceLoan.createRefinanceLoan(this.refinanceLoan);
        this.loan.refinanceLoanId = this.refinanceLoan.id;
        this.loan = await Loan.updateLoan(this.loan);
      }
    },
  },
  props: ['applicationId'],
}
</script>
