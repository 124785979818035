import Vue from "vue"
import numeral from "numeral";

Vue.filter("currency", function(value, format) {
    // if (!value) {
    //     return ''
    // }

    if (!format) {
        return numeral(value).format('$0,0');
    } else {
        return numeral(value).format(format)
    }
})

Vue.filter("percentage", function(value, format) {
    if (!value) {
        return ''
    }

    if (value > 1) {
        value = value / 100;
    }

    if (!format) {
        return numeral(value).format('0.000%');
    } else {
        return numeral(value).format(format);
    }
})
