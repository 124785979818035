<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container custom-modal-large">

          <div class="modal-header font-weight-bold">
            <slot name="header">Quote History for Application: {{borrower.firstName}} {{borrower.lastName}} - Application #{{application.id}}</slot>
          </div>

          <div class="modal-body">
            <div class="history-big-table-div">
              <table class="custom-table table table-sm table-hover mb-0">
                <thead class="custom-bg-med sticky-top">
                  <tr>
                    <th class="custom-td th" scope="col">Timestamp</th>
                    <th class="custom-td th" scope="col">Amortization Type</th>
                    <th class="custom-td th" scope="col">Loan Purpose</th>
                    <th class="custom-td th" scope="col">Property Use</th>
                    <th class="custom-td th" scope="col">Property Type</th>
                    <th class="custom-td th" scope="col">Loan Amount</th>
                    <th class="custom-td th" scope="col">Property Value</th>
                    <th class="custom-td th" scope="col">Purchase Price</th>
                    <th class="custom-td th" scope="col">Cash Out Amount</th>
                    <th class="custom-td th" scope="col">Interest Rate</th>
                    <th class="custom-td th" scope="col">APR</th>
                    <th class="custom-td th" scope="col">Fee</th>
                    <th class="custom-td th" scope="col">Credit</th>
                    <th class="custom-td th" scope="col">No. of Months</th>
                    <th class="custom-td th" scope="col">Credit Score</th>
                    <th class="custom-td th" scope="col">Monthly Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="highlighted-row" >
                    <td class="custom-td">{{formatDate(currentQuote.created)}}</td>
                    <td class="custom-td">{{currentQuote.amortizationType}}</td>
                    <td class="custom-td">{{currentQuote.loanPurpose}}</td>
                    <td class="custom-td">{{currentQuote.propertyUse}}</td>
                    <td class="custom-td">{{currentQuote.propertyType}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.loanAmount)}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.propertyValue)}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.purchasePrice)}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.cashOutAmount)}}</td>
                    <td class="custom-td">{{formatPercentage(currentQuote.interestRate)}}</td>
                    <td class="custom-td">{{formatPercentage(currentQuote.apr)}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.fee)}}</td>
                    <td class="custom-td">{{formatCurrency(currentQuote.credit)}}</td>
                    <td class="custom-td">{{currentQuote.numberOfMonths}}</td>
                    <td class="custom-td">{{currentQuote.creditRating}}</td>
                    <td class="custom-td" >{{formatCurrency(currentQuote.monthlyPayment)}}</td>
                  </tr>
                  <tr v-for="(history) in histories" :key="history.id">
                    <td class="custom-td">{{formatDate(history.created)}}</td>
                    <td class="custom-td">{{history.amortizationType}}</td>
                    <td class="custom-td">{{history.loanPurpose}}</td>
                    <td class="custom-td">{{history.propertyUse}}</td>
                    <td class="custom-td">{{history.propertyType}}</td>
                    <td class="custom-td">{{formatCurrency(history.loanAmount)}}</td>
                    <td class="custom-td">{{formatCurrency(history.propertyValue)}}</td>
                    <td class="custom-td">{{formatCurrency(history.purchasePrice)}}</td>
                    <td class="custom-td">{{formatCurrency(history.cashOutAmount)}}</td>
                    <td class="custom-td">{{formatPercentage(history.interestRate)}}</td>
                    <td class="custom-td">{{formatPercentage(history.apr)}}</td>
                    <td class="custom-td">{{formatCurrency(history.fee)}}</td>
                    <td class="custom-td">{{formatCurrency(history.credit)}}</td>
                    <td class="custom-td">{{history.numberOfMonths}}</td>
                    <td class="custom-td">{{history.creditRating}}</td>
                    <td class="custom-td" >{{formatCurrency(history.monthlyPayment)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import numeral from 'numeral'
import moment from "moment";

export default {
  name: 'applicationHistoryModal',
  computed: {
    currentQuote: function () {
      let currentQuote = {
        created: (this.application.loan.amortizationType !== undefined) ? this.histories[0].created : 0 ,
        amortizationType: (this.application.loan.amortizationType !== undefined) ? this.application.loan.amortizationType : '',
        loanPurpose: (this.application.loan.loanPurpose !== undefined) ? this.application.loan.loanPurpose : '',
        propertyUse: (this.application.property.propertyUse !== undefined) ? this.application.property.propertyUse : '',
        propertyType: (this.application.property.propertyType !== undefined) ? this.application.property.propertyType : '',
        loanAmount: (this.application.loan.amount  !== undefined) ? this.application.loan.amount : 0,
        propertyValue: (this.application.property.value !== undefined) ? this.application.property.value : 0,
        purchasePrice: (this.application.property.purchasePrice !== undefined) ? this.application.property.purchasePrice : 0,
        cashOutAmount: (this.refinanceLoan.cashOutAmount !== undefined) ? this.refinanceLoan.cashOutAmount : 0,
        interestRate: (this.application.loan.interestRate  !== undefined) ? this.application.loan.interestRate : 0,
        apr: (this.application.loan.apr  !== undefined) ? this.application.loan.apr : 0,
        fee: (this.application.loan.fee  !== undefined) ? this.application.loan.fee : 0,
        credit: (this.application.loan.credit  !== undefined) ? this.application.loan.credit : 0,
        numberOfMonths: (this.application.loan.numberOfMonths  !== undefined) ? this.application.loan.numberOfMonths : 0,
        creditRating: (this.borrower.creditRating !== undefined) ? this.borrower.creditRating : '',
        monthlyPayment: (this.application.loan.monthlyPayment  !== undefined) ? this.application.loan.mortgagePayment : 0,
      }

      console.log(JSON.stringify(currentQuote))

      return currentQuote;
    }
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatDate: function (date) {
      return moment(date).format('MM/DD/YY hh:mm a');
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.000%');
    },
  },
  props: ['application', 'histories', 'refinanceLoan', 'borrower'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 700px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
