import HTTP from './HttpService'

var ConstructionLoan = {

  getConstructionLoan: async function(constructionLoanId) {
    var constructionLoan = {};

    await HTTP.http().get('/constructionloan/' + constructionLoanId)
      .then(function(response) {
        if (response) {
          constructionLoan = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load construction loan error: ' + error);
      });

      return constructionLoan;
  },

  createConstructionLoan: async function (constructionLoanData) {
    var responseConstructionLoan = {};

    await HTTP.http().post('/constructionloan/create', constructionLoanData)
      .then(function(result) {
        responseConstructionLoan = result.data;
      })
      .catch(function(error) {
        console.log('Create construction loan error: ' + error);
      });

    return responseConstructionLoan;
  },

  deleteConstructionLoan: async function (constructionLoanId) {
    var deleteResponse = {};

    await HTTP.http().delete('/constructionloan/delete/' + constructionLoanId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete construction loan error: ' + error);
      });

    return deleteResponse;
  },

  updateConstructionLoan: async function (constructionLoanData) {
    var responseConstructionLoan = {};

    await HTTP.http().post('/constructionloan/update', constructionLoanData)
      .then(function(result) {
        responseConstructionLoan = result.data;
      })
      .catch(function(error) {
        console.log('Update construction loan error: ' + error);
      });

      return responseConstructionLoan;
  }

}

export default ConstructionLoan;
