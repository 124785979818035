<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">Borrower Address Detail for {{borrowerAddressData.borrower.firstName}} {{borrowerAddressData.borrower.lastName}}</slot>
          </div>
          <div class="modal-body">

            <form class="ml-2 mr-2">
              <div class="form-row">
                <div class="form-group col-md-12">

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-5">
                      <div class="form-group">
                        <label for="addressType" class="form-control-sm">Address Type</label>
                        <select
                          class="custom-select custom-select-sm"
                          id="addressType"
                          v-model="formData.address.addressType"
                          @change="changeAddress('addressType')"
                        >
                          <option
                            v-for="(addressType) in addressTypes"
                            :key="addressType.id"
                            :value="addressType.value"
                          >{{addressType.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-1"></div>
                    <div class="form-group col-md-6">
                      <label for="improvementsMadeRadio" class="form-control-sm">Current</label>
                      <br />
                      <div
                        v-for="(trueFalse) in trueFalseValues"
                        :key="trueFalse.id"
                        class="form-check form-check-inline"
                      >
                        <input
                          type="radio"
                          v-model="formData.address.current"
                          name="current"
                          :value="trueFalse.id"
                          @change="changeAddress('current')"
                        />
                        <label
                          class="form-check-label form-control-sm"
                          for="current"
                        >{{trueFalse.value}}</label>
                      </div>
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-5">
                      <div class="form-group">
                        <label for="addressType" class="form-control-sm">Address Status</label>
                        <select
                          class="custom-select custom-select-sm"
                          id="ownershipStatus"
                          v-model="formData.address.ownershipStatus"
                          @change="changeAddress('ownershipStatus')"
                        >
                          <option
                            v-for="(addressStatus) in addressStatuses"
                            :key="addressStatus.id"
                            :value="addressStatus.value"
                          >{{addressStatus.value}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-1"></div>
                    <div class="form-group col-md-6">
                      <label for="address" class="form-control-sm">Years at Address</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="yearsAtAddress"
                        placeholder="Years"
                        v-model="formData.address.yearsAtAddress"
                        @change="changeAddress('yearsAtAddress')"
                      />
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-12">
                      <label for="address" class="form-control-sm">Street Address</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="address"
                        placeholder="Address"
                        v-model="formData.address.address"
                        @change="changeAddress('address')"
                      />
                    </div>
                  </div>
                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-4">
                      <label for="city" class="form-control-sm">City</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="city"
                        placeholder="City"
                        v-model="formData.address.city"
                        @change="changeAddress('city')"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <div class="form-group">
                        <label for="stateId" class="form-control-sm">State</label>
                        <select
                          class="custom-select custom-select-sm"
                          id="stateId"
                          v-model="formData.address.stateId"
                          @change="changeAddress('stateId')"
                        >
                          <option
                            v-for="(state) in states"
                            :key="state.id"
                            :value="state.id"
                          >{{state.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="zip" class="form-control-sm">Zip</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="zip"
                        placeholder="Zip"
                        v-model="formData.address.zip"
                        @change="changeAddress('zip')"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </form>

          </div>
          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import lodash from 'lodash'

import Address from '@/services/AddressService'
import Borrower from '@/services/BorrowerService'
import PickDetail from '@/services/PickDetailService'
import State from '@/services/StateService'

export default {
  name: 'borrowerAddressModal',
  created: async function () {
    //
    // Load data from the API end points
    //

    // Address data

    if (this.borrowerAddressData.address != null && this.borrowerAddressData.address.id > 0) {
      this.address = await Address.getAddress(this.borrowerAddressData.address.id);
    } else {
      this.address.addressType = "Home";
      this.address.current = false;
      this.address.address = "New Address";
      this.address.city = "City";
      this.address.stateId = 0;
      this.address.zip = "00000";
    }
    this.formData.address = lodash.cloneDeep(this.address);

    // Lists for options

    this.addressStatuses = await PickDetail.getPickListDetail("addressstatus");
    this.addressTypes = await PickDetail.getPickListDetail("addresstype/shortlist");
    this.states = await State.getStates();
  },
  data: function () {
    return {
      address: {},
      addressStatuses: [],
      addressTypes: [],
      formData: {
        address: {},
      },
      states: [],
      trueFalseValues: [
        {
          id: "false",
          value: "No",
        },
        {
          id: "true",
          value: "Yes",
        },
      ],

    }
  },
  methods: {
    changeAddress: function (fieldName) {
      if (this.formData.address[fieldName] != this.address[fieldName]) {
        this.address[fieldName] = this.formData.address[fieldName];
        this.saveAddress();
      }
    },
    close: function () {
      this.updateParent('close');
    },
    saveAddress: async function() {
      if (this.address.id > 0) {
        delete(this.address.created);  // Parsing dates was causing an exception on the server
        delete(this.address.modified); // so kill these as they're set by the server anyway
        this.address = await Address.updateAddress(this.address);
      } else {
        var borrowerAddress;
        
        borrowerAddress = await Borrower.createBorrowerAddress(this.borrowerAddressData.borrower, this.address);
        this.address = await Address.getAddress(borrowerAddress.addressId);
        this.updateParent('addAddress');
      }
      this.formData.address = lodash.cloneDeep(this.address);
    },
    updateParent: function(emitMessage) {
      var returnBorrowerAddressData = {
        address: this.address,
        borrowerIdx: this.borrowerAddressData.borrowerIdx,
      }

      this.$emit(emitMessage, returnBorrowerAddressData);
    },
  },
  props: ['borrowerAddressData'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 650px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
